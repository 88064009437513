<template>
  <div class="scContent">
    <!-- 文本 -->
    <div class="text" v-html="aa" v-if="fromType == 1"></div>
    <div class="attachment" v-if="fromType == 1 && fileList.length>0" >
          附件下载：
        <div class="filedName" v-for="(item,index) in fileList" :key="index" @click="download(item)">
            <a>{{item.name}}</a>
        </div>
    </div>
    <!-- pdf -->
    <pdf v-else-if="fromType == 2" :nkpdfUrl="nkpdfUrl"></pdf>
    <!-- 嵌入外联 -->
    <iframe v-else-if="fromType == 3" :src="src" frameborder="0" ref="refed" width="100%" height="100%" ></iframe>
    <!-- 表单 -->
    <fromlist v-else-if="fromType == 4" :fromlist="fromlist1" :fromTittle="fromTittle"></fromlist>
    <!-- 列表 -->
    <sclist v-else-if="fromType == 5"  :contentList1="contentList1" :form="form"></sclist>
    <goPage v-if="$store.state.tyPhone == 'ios'"></goPage>
  </div>
</template>
<script>
import pdf from '@/components/pdf/pdf.vue'
import fromlist from '@/components/contentlist/fromlist.vue'
import sclist from '@/views/scenarioService/scList/scList.vue'
import {getSceneContentDetail ,getSceneContentByColumnId} from '@/api/home.js'
import axios from 'axios'

export default {
  name:"scContent",
  data() {
    return {
      src:'http://www.baidu.com',
      nkpdfUrl:'',
      aa:"",
      fromType:1,
      fromlist1:"",
      fromTittle:"",
      listType:'',
      detailsId:'',
      contentList1:[],
      fileList:[],
      fileTypeMime:'',
      form:''
    }
  },
  components:{
    pdf,
    fromlist,
    sclist
  },
  created() {
    this.init()
  },
  computed:{
    topTittle(){
      return localStorage.getItem('tittle')
    }
  },
  mounted(){},
  methods: {
    // 下载
    download(item){
      this.$toast.loading({
        message: '加载中',
        duration: 0, // 会一直存在,如果有下一个this.$toast被调用，它就会被覆盖，也就消失了
        mask: true
      });
      let link = document.createElement('a')
      link.style.display = 'none'
      let url = item.url//绝对地址
      link.href = url
      link.download = item.name//文件名
      document.head.appendChild(link)
      link.click()
      document.head.removeChild(link)            
      this.$toast.clear();
    },
    async init(){
      if(this.$route.query.typeId != 5 ){
        let  data = await getSceneContentDetail(this.$route.query.id)
        console.log(data)
        if(this.$store.state.jxbApp){
            window.location.href=`ssptitle://${data.data.data.title}`
        }else{
            document.title = data.data.data.title
        }
        this.listType = data.data.data
      }else{
        if(this.$store.state.jxbApp){
            window.location.href=`ssptitle://${localStorage.getItem('tittle')}`
        }else{
            document.title = localStorage.getItem('tittle')
        }
        let data = await getSceneContentByColumnId({
          "columnId": this.$route.query.id,
          "pageNumber": 1,
          "pageSize": 10,
          "title": ""
        })
        this.listType = data.data.data.rows
      }
      switch (this.$route.query.typeId) {
        case "1":
        let a = this.listType.content.replace(/<img/g, '<img style="max-width: 100%;"')
        this.aa = '<div style="font-size:16px;">'+a+'</div>'
        this.fromType = this.$route.query.typeId
        if(this.listType.fileId || this.listType.fileName){
          let fileUrl = this.listType.fileId.split(',')
          let fileName = this.listType.fileName.split(',')
          for(let i in fileUrl){
              let obj = {
                  url:fileUrl[i],
                  name:fileName[i]
              }
              this.fileList.push(obj)
          }
        }
        break;
        case "2":
        this.nkpdfUrl = this.listType.pdfFileId 
        this.fromType = this.$route.query.typeId
        break
        case "3":
        this.src = this.listType.outUrl 
        this.fromType = this.$route.query.typeId
        break
        case "4":
        this.fromlist1 = this.listType.formInfo 
        this.fromTittle = this.listType.title 
        this.fromType = this.$route.query.typeId
        break;
        default:
        // console.log(this.listType)
        if(this.listType.length>0){
          if(this.listType[0].content){
            this.form = this.listType[0].content.replace(/<img/g, '<img style="max-width: 100%;"')
          }
          let arr = this.listType
          arr.shift()
          this.contentList1 = arr;
        }
        this.fromType = this.$route.query.typeId
        break;
      }
      
    }
  },
}
</script>
<style lang="less" scoped>
  .text{
      padding: 0 0.542rem;
      box-sizing: border-box;
    }
  .attachment{
    font-size: 0.433rem;
    padding: 0 0.542rem;
    
    .filedName{
        color: #0000d9;
        margin: .178rem 0;
        /* cursor: pointer; */
    }
    a{
      display: block;
    }
}
</style>